/* CSS variables. */
:root {
    --PhoneInput-color--focus:#1976d2; 
    
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 1em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: 0.3em;
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: currentColor; 
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus); 
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
    /* This is done to stretch the contents of this component. */
    display: flex;
    align-items: center;
    
    background-color:#F6F7F7;
    justify-content: center;
    padding-left: 4px;
    border: 1px solid rgb(188, 188, 188);
    border-radius: 4px;
    height: 55px;
 

}

.PhoneInput:focus,
.PhoneInput:active,
.PhoneInput:focus-within {
    border:2px solid #1976d2;

    
}

.PhoneInput:hover:not(:focus-within) {
    border: 1px solid rgb(133, 133, 133);
  
}

.PhoneInputInput {
  
    flex: 1;

    min-width: 0;
    border:none;
height: 55px;
background-color:transparent;
outline: none;


}

.PhoneInputCountryIcon {
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
    height: var(--PhoneInputCountryFlag-height);
  
    padding-right: 4px;
}

.PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);

}

.PhoneInputCountryIcon--border {
 
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
        inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  
    display: block;
   
    width: 100%;
    height: 100%;
}

.PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
    cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon+.PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
        inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}